import {inject} from "@angular/core"

import {BehaviorSubject, defer, forkJoin, map, of, retry, startWith, Subscription, switchMap, tap, timer} from "rxjs"
import {Icons, ModeBarButton} from "plotly.js-dist-min"
import {PlotlyComponent} from "angular-plotly.js"

import {MapOverlay, Project, VideObject} from "../api/api-types"
import {PLOT_CONFIG} from "../../constants"
import {VideDataService} from "../api/vide-data.service"
import {
    getNormalPosition, MAP_BACKGROUNDS,
    MAP_BACKGROUNDS_APIKEY,
    MAP_BACKGROUNDS_PUBLIC,
    MapBackground,
    MapPosition
} from "./mapbox-helper"
import {omit, PlotlyConfig, PlotlyLayout, PlotlyRelayoutEvent, VideObjectWithPosition,} from "../vide-types"
import {FormBuilder} from "@angular/forms"

type MapElement = {
    description: string,
    selected: boolean,
} & ({
    type: "raster",
    url: string,
} | {
    type: "geojson",
    id: number,
})

/**
 *
 * Use this in the component where the plot is rendered:
 * ```
 * plotlyComponent = viewChild(PlotlyComponent)
 * <...>
 * constructor(){
 *   effect(() => {
 *     this.dataService.plotlyHost2 = this.plotlyComponent()
 *   })
 * }
 * ```
 */
export abstract class AbstractMapboxService {
    private readonly sguJordlagerUrl =
        // 'https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg',
        'https://maps3.sgu.se/geoserver/jord/ows?' +
        'bbox={bbox-epsg-3857}&' +
        'format=image/png&' +
        'service=WMS&' +
        // 'version=1.1.1&' +
        'version=1.3.0&' +
        'request=GetMap&' +
        'srs=EPSG:3857&' +
        'transparent=true&' +
        'width=256&' +
        'height=256&' +
        'layers=jord:SE.GOV.SGU.JORD.GRUNDLAGER.25K'

    // TODO: this distinction seems bogus now! Labels show up in both!
    readonly MAP_BACKGROUNDS_OLD = [
        {
            title: 'Normal backgrounds',
            names: MAP_BACKGROUNDS_APIKEY,
        },
        {
            title: 'Background where label will not work',
            names: MAP_BACKGROUNDS_PUBLIC,
        },
    ] as const
    private plotlyElement?: HTMLElement
    private mapPosition?: MapPosition
    private elementFetcher?: Subscription

    protected readonly forceRelayout$ = new BehaviorSubject<void>(undefined)
    protected abstract readonly useColorbar: boolean

    protected dataService = inject(VideDataService)
    protected readonly formBuilder = inject(FormBuilder)

    protected mapForm = this.formBuilder.nonNullable.group({
        background: ['outdoors' as MapBackground],
        elements: [new Array<MapElement>()],
    })

    readonly mapElements$ = this.dataService.projectNotNull$.pipe(
        map(p => {
            const jordarter = {
                url: this.sguJordlagerUrl,
                type: "raster" as const,
                description: "SGU jordlager (not visible in small scale)",
                selected: false,
            }
            const x = p.map_overlays.map(o => ({
                type: "geojson" as const,
                ...o,
            }))
            const ret: readonly MapElement[] = [jordarter, ...x]
            return ret
        }),
        tap(x => {
            // Set the control only if this list is used
            const selected = x.filter(e => e.selected)
            this.mapForm.patchValue({elements: selected})
        }),
    )

    /**
     * Use this when you have a viewChild signal that gives the plotly component.
     * @param component
     */
    set plotlyHost2(component: PlotlyComponent | undefined) {
        if (component) {
            this.elementFetcher = this.fetchPlotlyElement2(component).subscribe({
                next: e => {
                    this.plotlyElement = e
                    this.forceRelayout$.next()
                },
                error: err => {
                    console.warn(`${err}`)
                }
            })
        } else {
            this.elementFetcher?.unsubscribe()
            this.plotlyElement = undefined
        }
    }

    private zoomAutoscaleButton: ModeBarButton = {
        name: 'zoomAutoscale',
        click: this.zoomAutoscale.bind(this),
        icon: Icons.autoscale,
        // icon: 'asdf',
        title: 'Autoscale zoom',
    }

    protected zoomAutoscale() {
        this.clearPosition()
        this.forceRelayout$.next()
    }

    protected clearPosition() {
        this.mapPosition = undefined
    }

    protected getMapConfig(options?: { filename: string }): PlotlyConfig {
        return {
            showEditInChartStudio: true, // icon in modebar
            plotlyServerURL: "https://chart-studio.plotly.com",
            modeBarButtonsToRemove: [
                'toImage',
                'select2d',
                'lasso2d',
                'resetScale2d',
                'resetViewMapbox',
            ],
            modeBarButtonsToAdd: [
                this.zoomAutoscaleButton,
                this.dataService.plotlySnapshotButton,
            ],
            mapboxAccessToken: PLOT_CONFIG.mapboxAccessToken,
            toImageButtonOptions: {filename: options?.filename},
            scrollZoom: true,
        }
    }

    protected getLayout(
        project: Project,
        objects: readonly Pick<VideObject, 'position' | 'positionM'>[],
        options?: {
            // background: MapBackground,
            ignoreUserActions?: boolean,
            // overlayIds?: number[],
        },
        // ignoreUserActions = false,
    ) {
        let position: MapPosition
        if (options?.ignoreUserActions === true || this.mapPosition === undefined) {
            position = getNormalPosition(objects, this.plotlyElement, this.useColorbar)
        } else {
            position = this.mapPosition
        }

        const formValues = this.mapForm.getRawValue()
        const observables = formValues.elements
            .filter(e => e.type === "geojson")
            .map(e => this.dataService.getMapOverlay(project, e.id))
        const rasterLayers = formValues.elements
            .filter(e => e.type === "raster")
            .map(e => {
                return {
                    sourcetype: "raster" as const,
                    source: [e.url] as any,
                    opacity: 0.3,
                }
            })
        const overlays2$ = observables.length > 0 ? forkJoin(observables) : of([])
        return overlays2$.pipe(
            switchMap(overlays => {
                const layers = overlays.flatMap(this.getLayers).concat(rasterLayers)
                const ret: PlotlyLayout = {
                    mapbox: {
                        style: formValues.background,
                        center: position.center,
                        zoom: position.zoom,
                        layers,
                    },
                    margin: {r: 0, t: 0, b: 0, l: 0},
                    showlegend: false,
                    autosize: true,
                }
                // TODO: A hack to force the layers to render properly. Something wrong with angular-plotly?
                const retBogus = {
                    ...ret,
                    mapbox: {
                        ...ret.mapbox,
                        layers: [],
                    },
                }
                return timer(100).pipe(
                    map(_ => ret),
                    startWith(retBogus),
                )
            }),
        )


    }

    private getLayers(overlay: MapOverlay) {
        // split in three layers: filter polygon, line and point types from the geojson
        const res: NonNullable<NonNullable<PlotlyLayout['mapbox']>['layers']> = []
        const jsonLine = {
            ...omit(overlay.geojson, 'features'),
            features: overlay.geojson.features.filter((x: any) => ['LineString', 'MultiLineString'].includes(x.geometry.type))
        } as any
        const jsonPolygon = {
            ...omit(overlay.geojson, 'features'),
            features: overlay.geojson.features.filter((x: any) => ['Polygon', 'MultiPolygon'].includes(x.geometry.type))
        } as any
        const jsonPoints = {
            ...omit(overlay.geojson, 'features'),
            features: overlay.geojson.features.filter((x: any) => ['Point', 'MultiPoint'].includes(x.geometry.type))
        } as any
        const baseLayer: typeof res[number] = {
            below: 'traces',
            opacity: overlay.opacity,
            sourcetype: "geojson",
        }
        if (jsonLine.features.length > 0 && overlay.line_color) {
            res.push({
                    ...baseLayer,
                    color: overlay.line_color,
                    line: {width: overlay.width,},
                    source: jsonLine,
                    type: 'line',
                }
            )
        }
        if (jsonPolygon.features.length > 0) {
            // Polygons get both surface and line layer. This way, we can have either one of them or both.
            if (overlay.fill_color) {
                res.push({
                        ...baseLayer,
                        color: overlay.fill_color,
                        fill: {outlinecolor: overlay.fill_color},
                        source: jsonPolygon,
                        type: 'fill',
                    }
                )
            }
            if (overlay.line_color) {
                res.push({
                        ...baseLayer,
                        color: overlay.line_color,
                        line: {width: overlay.width,},
                        source: jsonPolygon,
                        type: 'line',
                    }
                )
            }
        }
        if (jsonPoints.features.length > 0) {
            res.push({
                    ...baseLayer,
                    source: jsonPoints,
                    type: 'symbol',
                }
            )
        }
        return res
    }

    plotlyRelayout(event: PlotlyRelayoutEvent) {
        if (event['mapbox.center'] && event['mapbox.zoom']) {
            this.mapPosition = {
                center: event['mapbox.center'],
                zoom: event['mapbox.zoom'],
            }
        }
    }

    /**
     * Zoom to the given objects
     * @param objects
     */
    protected zoomToObjects(objects: VideObjectWithPosition[]) {
        console.warn(this)
        this.mapPosition = getNormalPosition(objects, this.plotlyElement, this.useColorbar)
        this.forceRelayout$.next()
    }

    private fetchPlotlyElement2(host: PlotlyComponent) {
        return defer(() => {
            let instance = host.plotlyInstance
            // console.warn(`Locating element:`, instance)
            if (!instance) throw new Error("Instance not found")
            return of(instance)
        }).pipe(
            retry({count: 5, delay: 1500}),
            // catchError(err => of("Giving up, element not found"))
        )
    }

}
